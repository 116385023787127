body {
    margin: 0;
    font-family: "IBM Plex Sans", sans-serif;
    /* color: white !important; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input[type="file"] {
    display: none;
}

::-webkit-scrollbar {
    display: none;
}

button.mapboxgl-popup-close-button {
    height: 30px !important;
    width: 30px !important;
    font-size: 24px !important;
    padding: 0 !important;
    line-height: 1 !important;
    border-top-right-radius: 8px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome and Opera */
}

.react-tel-input .form-control {
    font-family: "IBM Plex Sans" !important;
    width: 244px !important;
    height: 37px !important;
    background-color: transparent !important;
    color: white !important;
    border: none !important;
    border-radius: 8px !important;
    margin: 10px !important;
    font-size: 14px !important;
    outline: none !important;
}

.react-tel-input .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    outline: none !important;
}

.react-tel-input .form-control::placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    border-radius: 8px 0 0 8px !important;
    background: transparent !important;
}

.react-tel-input .arrow {
    display: none !important;
}

.geosuggest {
    position: fixed;
    top: 22px;
    right: 85px;
    z-index: 10;
}

@media only screen and (max-width: 600px) {
    .geosuggest {
        position: fixed;
        top: 28px;
        right: 0px;
        z-index: 10;
    }
}

.geosuggest__input {
    outline: none;
    border: none;
    border-radius: 10px;
    font-family: IBM Plex Sans;
    width: 260px;
    height: 35px;
    padding: 0 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.geosuggest__suggests-wrapper {
    background: white;
    margin-top: 15px;
    width: 300px;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.geosuggest__suggests-wrapper ul {
    list-style: none;
    margin: 5px 0;
    padding: 0px 5px;
}

.geosuggest__suggests-wrapper li {
    padding: 7px 7px;
    margin: 0;
}

.geosuggest__suggests-wrapper li:hover {
    border-radius: 2px;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
    background: transparent;
}
